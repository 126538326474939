.required {
  color: var(--ion-color-danger);
}

//separator for divider replace
.separator{
  &-top{
    border-top: 1px solid;
    border-color: var(--ion-color-tertiary-tint);

  }
  &-bottom{
    border-bottom: 1px solid;
    border-color: var(--ion-color-tertiary-tint);
  }
}


//modal add work order
.action-sheet-ot .action-sheet-title{
  background: var(--ion-color-light);
  font-size: 1.2rem;
  text-align: center;

}

//modal add work order action button
.action-sheet-ot .action-sheet-button-inner{
  justify-content: center;
}

//modal add work order action button icon
.action-sheet-ot .action-sheet-icon{
  margin-inline-end: 1rem;

}

//modal add work order cancel button
.action-sheet-ot .action-sheet-group-cancel{
  background-color: var(--ion-color-danger-400);
}

//modal add work order cancel button not ios
.action-sheet-ot .action-sheet-group-cancel .sc-ion-action-sheet-md{
  justify-content: center;
  color: white;
}
