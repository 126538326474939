// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
	/** primary **/
	--ion-color-primary: #192e61;
	--ion-color-primary-rgb: 56, 128, 255;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255, 255, 255;
	--ion-color-primary-shade: #192e61;
	--ion-color-primary-tint: #192e61;

	--ion-color-primary-step-50:  #D6D6D6;
	--ion-color-primary-step-100: #B7B7B7;
	--ion-color-primary-step-200: #939599;
	--ion-color-primary-step-300: #6E768D;
	--ion-color-primary-step-400: #4E5D85;
	--ion-color-primary-step-500: #30477F;
	--ion-color-primary-step-600: #2A4077;
	--ion-color-primary-step-700: #22376B;
	--ion-color-primary-step-800: var(--ion-color-primary);
	--ion-color-primary-step-900: #041C4F;

	/** secondary **/
	--ion-color-secondary: #19afab;
	--ion-color-secondary-rgb: 61, 194, 255;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255, 255, 255;
	--ion-color-secondary-shade: #19afab;
	--ion-color-secondary-tint: #1cd1cb;

	--ion-color-secondary-step-50:  #E2E8E8;
	--ion-color-secondary-step-100: #B5D3D3;
	--ion-color-secondary-step-200: #86BFBD;
	--ion-color-secondary-step-300: var(--ion-color-secondary);
	--ion-color-secondary-step-400: #349C97;
	--ion-color-secondary-step-500: #1F8C85;
	--ion-color-secondary-step-600: #197E77;
	--ion-color-secondary-step-700: #1B7068;
	--ion-color-secondary-step-800: #116159;
	--ion-color-secondary-step-900: #0B443C;

	/** tertiary **/
	--ion-color-tertiary: #969696;
	--ion-color-tertiary-rgb: 82, 96, 255;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255, 255, 255;
	--ion-color-tertiary-shade: #969696;
	--ion-color-tertiary-tint: #bdbcbc;

	/** success **/
	--ion-color-success: #2dd36f;
	--ion-color-success-rgb: 45, 211, 111;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255, 255, 255;
	--ion-color-success-shade: #28ba62;
	--ion-color-success-tint: #42d77d;

	/** warning **/
	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255, 196, 9;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0, 0, 0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	/** danger **/
	--ion-color-danger: #eb445a;
	--ion-color-danger-rgb: 235, 68, 90;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255, 255, 255;
	--ion-color-danger-shade: #cf3c4f;
	--ion-color-danger-tint: #ed576b;

	--ion-color-danger-50:  #EFEFEF;
	--ion-color-danger-100: #E7D3D8;
	--ion-color-danger-200: #D99FA8;
	--ion-color-danger-300: #D27988;
	--ion-color-danger-400: #E3596E;
	--ion-color-danger-500: --ion-color-danger;
	--ion-color-danger-600: #DA3D56;
	--ion-color-danger-700: #C8324E;
	--ion-color-danger-800: #BC2C46;
	--ion-color-danger-900: #AF203A;


	/** dark **/
	--ion-color-dark: #222428;
	--ion-color-dark-rgb: 34, 36, 40;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255, 255, 255;
	--ion-color-dark-shade: #1e2023;
	--ion-color-dark-tint: #383a3e; 

	/** medium **/
	--ion-color-medium: #92949c;
	--ion-color-medium-rgb: 146, 148, 156;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255, 255, 255;
	--ion-color-medium-shade: #808289;
	--ion-color-medium-tint: #9d9fa6;

	/** light **/
	--ion-color-light: #f4f5f8;
	--ion-color-light-rgb: 244, 245, 248;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0, 0, 0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;
}
